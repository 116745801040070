import { createGlobalStyle } from 'styled-components';
import '../../node_modules/normalize.css/normalize.css';
import './resets.css';

export const colors = {
  grey: '#E6E6E6',
  white: '#FFFFFF',
  blue: '#6D84B6',
  lightBlack: '#393435',
  red: '#E55C60',
  lightRed: '#E0B4B4',
  lightGrey: '#D3D3D0',
  darkGrey: '#393435',
  darkGrey2: '#817E7F',
  grey1: '#EEEEEE',
  grey2: '#CAC9C9',
  grey4: '#817E7F',
  grey5: '#5D595A',
  slate: '#393435',
  cream: '#FFEBC2',
  pink: '#EEC6B5',
  sage: '#B3CDB5',
  purple: '#B5ABDB',
  cyan: '#9FCDD6',
};

export const fonts = {
  gotham: '"Gotham SSm", Helvetica, sans-serif',
  serif: 'serif',
};

export const styles = {
  colors,
  maxWidth: '86%',
  footerHeight: '12rem',
  baseFontColor: colors.darkGrey,
  backgroundColor: colors.white,
  headerBackground: '#393435',
  footerBackground: '#393435',
  bodyBackground: '#FFFFFF',
  borderColor: colors.lightGrey,
  radioColor: colors.darkGrey,
  desktopBreakpoint: '62em',
  bannerBreakpoint: '40em',
  mobileBreakpoint: '36rem',
  tabletBreakpoint: '920px',
};

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '426px',
  tabletS: '600px',
  tabletM: '800px',
  tablet: '921px',
  laptop: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktopS: '1650px',
  desktop: '2560px'
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tabletM: `(max-width: ${size.tabletM})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktop: `(max-width: ${size.desktop})`,
};

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
    line-height: 1.5;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    font-family: Gotham SSm, Helvetica Neue, Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 100%;
    color: ${styles.baseFontColor};
    background-color: ${styles.bodyBackground};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  a {
    color: #FFF;
    text-decoration: none;
  }
`;

export default styles;
