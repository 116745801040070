/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import { Link } from 'gatsby';
import AllHealthLogo from '../svgs/all-health-logo.svg';
import MenuHamburgerIcon from '../svgs/menu-hamburger.svg';
import XBigIcon from '../svgs/x-big.svg';
import { device, styles, colors } from '../styles/globals';
import { clearSession } from '../services/keycloak';
import { loadState } from '../utils/localStorage';

const HeaderEl = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${styles.headerBackground};
  color: ${colors.white};
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  @media ${device.tablet} {
    height: 90px;
  }

  @media ${device.mobileL} {
    height: 60px;
  }
`;

const HeaderWrap = styled.div`
  height: 100%;
  width: 100%;
  padding: 36px 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media ${device.desktopS} {
    padding: 36px 10%;
  }

  @media ${device.laptopL} {
    padding: 36px 6%;
  }

  @media ${device.laptop} {
    padding: 36px 2%;
  }

  @media ${device.tablet} {
    width: 100%;
    padding: 24px 35px 24px 55px;
  }
  
  @media ${device.mobileL} {
    padding: 14px 35px;
  }
`;

const HeaderNav = styled.nav`
  @media ${device.tablet} {
    display: ${props => props.isOpen ? 'block' : 'none'};
  }
`;

const HeaderNavList = styled.ul`
  list-style: none;
  display: flex;

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

const HeaderNavListItem = styled.li`
  padding: 14px 0;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid ${colors.grey5};

  &:first-child {
    border-top: 1px solid ${colors.grey5};
  }
`;

const HeaderLink = styled(Link)`
  opacity: 0.5;
  margin-left: 24px;
  &:hover {
    opacity: 1;
  }

  @media ${device.laptopM} {
    margin-left: 14px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    opacity: 1;
  }
`;

const HeaderHyperlink = styled.a`
  opacity: 0.5;
  margin-left: 24px;
  &:hover {
    opacity: 1;
  }

  @media ${device.laptopM} {
    margin-left: 14px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    opacity: 1;
  }
`;

const HeaderText = styled.span`
  opacity: 0.5;
  margin-left: 24px;
  pointer-events: none;

  @media ${device.laptopM} {
    margin-left: 14px;
  }

  @media ${device.tablet} {
    font-size: 14px;
  }
`;

const HeaderBurger = styled.div`
  display: none;

  @media ${device.tablet} {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    width: 90px;
  }

  @media ${device.mobileL} {
    height: 60px;
    width: 60px;
  }
`;

const HamburgerIcon = styled(MenuHamburgerIcon)`
  width: 28px;
  height: 28px;

  @media ${device.mobileL} {
    width: 18px;
    height: 18px;
  }
`;

const CloseIcon = styled(XBigIcon)`
  width: 28px;
  height: 28px;

  @media ${device.mobileL} {
    width: 18px;
    height: 18px;
  }
`;

const HeaderNavMob = styled.nav`
  display: none;
  position: absolute;
  width: 100%;
  background-color: ${styles.headerBackground};
  color: ${colors.white};
  z-index: 1000;
  padding: ${props => props.open ? '0 35px 35px' : '0 35px'};
  height: ${props => props.open ? '380px' : '0px'};
  transition: height .7s, padding .7s;
  overflow: hidden;

  @media ${device.tablet} {
    display: flex;
    top: 90px;
  }

  @media ${device.mobileL} {
    top: 60px;
  }
`;

const HeaderLinkMob = styled(Link)`
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const HeaderHyperlinkMob = styled.a`
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

const HeaderTextMob = styled.span`
  opacity: 0.5;
`;

const LogoLink = styled(Link)`
  height: 100%;
  width: 139px;

  @media ${device.tablet} {
    width: 122px;
  }

  @media ${device.mobileL} {
    width: 93px;
  }
`;

const getSupportLink = () => {
  const clinicalFAQ = loadState('clinicalFAQ');
  const patientFAQ = loadState('patientFAQ');
  const realm = loadState('realm', 'STRING');
  return realm ? realm === 'patient' ? patientFAQ : clinicalFAQ : '';
};

const Header = () => {
  const { keycloak } = useKeycloak();
  const [isOpen, setIsOpen] = useState(false);
  const [supportLink, setSupportLink] = useState(getSupportLink());

  useEffect(() => {
    const handleStorageChange = () => {
      const newLink = getSupportLink();
      setSupportLink(newLink);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderEl>
      {keycloak?.authenticated ? (
        <>
          <HeaderWrap>
            <LogoLink to="/">
              <AllHealthLogo style={{ height: '100%', width: '100%' }} />
            </LogoLink>
            <HeaderNav>
              <HeaderNavList>
                <li>
                  <HeaderLink to="/" activeStyle={{ opacity: 1 }}>
                    home
                  </HeaderLink>
                </li>
                <li>
                  <HeaderLink to="/technology" activeStyle={{ opacity: 1 }}>
                    technology
                  </HeaderLink>
                </li>
                <li>
                  <HeaderLink to="/clinical-science" activeStyle={{ opacity: 1 }}>
                    clinical science
                  </HeaderLink>
                </li>
                <li>
                  <HeaderLink to="/solutions" activeStyle={{ opacity: 1 }}>
                    solutions
                  </HeaderLink>
                </li>
                <li>
                  <HeaderLink to="/what-it-means-for-you" activeStyle={{ opacity: 1 }}>
                    what it means for you
                  </HeaderLink>
                </li>
                <li>
                  {
                    supportLink
                      ? (
                        <HeaderHyperlink
                          target="_blank"
                          rel="noopener noreferrer"
                          href={supportLink}
                          role="link"
                        >
                          support
                        </HeaderHyperlink>
                      ) : (
                        <HeaderText>
                          support
                        </HeaderText>
                      )
                  }
                </li>
                <li>
                  <HeaderLink
                    to="/"
                    onClick={event => {
                      event.preventDefault();
                      clearSession();
                      keycloak.logout({
                        redirectUri: `${window.location.origin}/`,
                      });
                    }}
                  >
                    sign out
                  </HeaderLink>
                </li>
              </HeaderNavList>
            </HeaderNav>
            <HeaderBurger onClick={handleClick}>
              {isOpen
                ? <CloseIcon />
                : <HamburgerIcon />}
            </HeaderBurger>
          </HeaderWrap>
          <HeaderNavMob open={isOpen}>
            <HeaderNavList>
              <HeaderNavListItem>
                <HeaderLinkMob to="/" activeStyle={{ opacity: 1 }}>
                  home
                </HeaderLinkMob>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <HeaderLinkMob to="/technology" activeStyle={{ opacity: 1 }}>
                  technology
                </HeaderLinkMob>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <HeaderLinkMob to="/clinical-science" activeStyle={{ opacity: 1 }}>
                  clinical science
                </HeaderLinkMob>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <HeaderLinkMob to="/solutions" activeStyle={{ opacity: 1 }}>
                  solutions
                </HeaderLinkMob>
              </HeaderNavListItem>
              <HeaderNavListItem>
                <HeaderLinkMob to="/what-it-means-for-you" activeStyle={{ opacity: 1 }}>
                  what it means for you
                </HeaderLinkMob>
              </HeaderNavListItem>
              <HeaderNavListItem>
                {
                  supportLink
                    ? (
                      <HeaderHyperlinkMob
                        target="_blank"
                        rel="noopener noreferrer"
                        href={supportLink}
                      >
                        support
                      </HeaderHyperlinkMob>

                    ) : (
                      <HeaderTextMob>support</HeaderTextMob>
                    )
                }
              </HeaderNavListItem>
              <HeaderNavListItem>
                <HeaderLinkMob
                  to="/"
                  onClick={event => {
                    event.preventDefault();
                    clearSession();
                    keycloak.logout({
                      redirectUri: `${window.location.origin}/`,
                    });
                  }}
                >
                  sign out
                </HeaderLinkMob>
              </HeaderNavListItem>
            </HeaderNavList>
          </HeaderNavMob>
        </>
      ) : (
        <HeaderWrap>
          <LogoLink to="/">
            <AllHealthLogo style={{ height: '100%', width: '100%' }} />
          </LogoLink>
          <HeaderNav isOpen>
            <HeaderNavList>
              <li>
                <HeaderLink to="/sign" activeStyle={{ opacity: 1 }}>
                  sign in
                </HeaderLink>
              </li>
            </HeaderNavList>
          </HeaderNav>
        </HeaderWrap>
      )}
    </HeaderEl>
  );
};

export default Header;
