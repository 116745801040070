import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { GlobalStyles, colors, device } from '../styles/globals';
import Header from '../components/header';
import Footer from '../components/footer';
import { fetchJsonFile, loadState, saveState } from '../utils/localStorage';

import favicon from '../favicon.ico';
import faviconSVG from '../favicon.svg';
import appleicon from '../images/apple-touch-icon.png';
import { isBrowser } from '../services/keycloak';

const PageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Main = styled.main`
  position: relative;
  flex: 1;
  background-color: ${colors.grey5};
  padding-top: 120px;

  @media ${device.tablet} {
    padding-top: 90px;
  }

  @media ${device.mobileL} {
    padding-top: 60px;
  }
`;

const DefaultLayout = ({ children, noLogin }) => {
  const { initialized, keycloak } = useKeycloak();
  const clinicalFAQ = loadState('clinicalFAQ');
  const patientFAQ = loadState('patientFAQ');

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (
      !keycloak.authenticated
      || !keycloak.token
      || !keycloak.tokenParsed
    ) {
      return;
    }

    const fetchAppConfig = async () => {
      try {
        const response = await fetch('https://radius-stage.all.health/configurator/api/v1/config', {
          method: 'GET',
          headers: {
            Authorization: keycloak.authenticated ? `Bearer ${keycloak.token}` : undefined,
          },
        });
        const configURL = await response.text();
        const config = await fetchJsonFile(configURL);
        const tenant = localStorage.getItem('tenant');
        const tenantUrl = `${config?.['tenant_config_path_url']}${tenant}_web.json`;
        const tenantConfig = await fetchJsonFile(tenantUrl);
        saveState('clinicalFAQ', tenantConfig?.hosts?.clinicalFAQ);
        saveState('patientFAQ', tenantConfig?.hosts?.patientFAQ);
        window.dispatchEvent(new Event('storage'));
      } catch (e) {
        console.error('Error fetching configuration:', e);
      }
    };
    if (!(clinicalFAQ && patientFAQ)) {
      fetchAppConfig();
    }
  }, [
    initialized,
    keycloak.authenticated,
    keycloak.token,
    keycloak.tokenParsed,
    clinicalFAQ,
    patientFAQ,
  ]);

  if (isBrowser() && !initialized && !noLogin) {
    return null;
  }

  if (isBrowser() && !keycloak.authenticated && !noLogin) {
    const selectedRealm = localStorage.getItem('realm');
    if (selectedRealm) {
      keycloak.login();
    } else if (isBrowser()) {
      navigate('/sign');
    }
    return null;
  }

  return (keycloak?.authenticated || noLogin) ? (
    <>
      <GlobalStyles />
      <Helmet>
        <title>all.health</title>
        <meta name="description" content="We enable the most advanced, continuous healthcare for all people - regardless of where they live, their income, or their health status." />
        <link rel="canonical" href="https://all.health/" />
        <link rel="icon" href={favicon} sizes="any" />
        <link rel="icon" href={faviconSVG} type="image/svg+xml" />
        <link rel="apple-touch-icon" href={appleicon} />
        <link rel="dns-prefetch" href="https://cloud.typography.com/" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cloud.typography.com/7512656/7962592/css/fonts.css"
        />
      </Helmet>
      <PageWrapper>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </PageWrapper>
    </>
  ) : (
    <>
      <GlobalStyles />
      <Helmet>
        <title>all.health</title>
        <meta name="description" content="We enable the most advanced, continuous healthcare for all people - regardless of where they live, their income, or their health status." />
        <link rel="canonical" href="https://all.health/" />
        <link rel="icon" href={favicon} sizes="any" />
        <link rel="icon" href={faviconSVG} type="image/svg+xml" />
        <link rel="apple-touch-icon" href={appleicon} />
        <link rel="dns-prefetch" href="https://cloud.typography.com/" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cloud.typography.com/7512656/7962592/css/fonts.css"
        />
      </Helmet>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  noLogin: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noLogin: false,
};

export default DefaultLayout;
