import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import styled from 'styled-components';
import { Link } from 'gatsby';
import AllHealthSm from '../svgs/all-health-logo-sm.svg';
import AllHealth from '../svgs/all-health-logo.svg';
import { styles, colors, device } from '../styles/globals';
import { clearSession } from '../services/keycloak';

const AllHealthLogo = styled(AllHealth)`
  @media ${device.mobileL} {
    display: none;
  }
`;

const AllHealthLogoSm = styled(AllHealthSm)`
  display: none;
  @media ${device.mobileL} {
    display: block;
  }
`;

const FooterEl = styled.footer`
  background-color: ${styles.footerBackground};
  color: ${colors.white};
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.tabletS} {
    height: 372px;
  }

  @media ${device.mobileL} {
    height: 288px;
  }
`;

const FooterWrap = styled.div`
  height: 100%;
  width: 100%;
  padding: 96px 15%;
  display: flex;
  align-items: center;

  @media ${device.desktopS} {
    padding: 96px 10%;
  }

  @media ${device.laptopL} {
    padding: 96px 6%;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding: 76px 6%;
  }

  @media ${device.tabletS} {
    padding: 60px 55px;
    align-items: flex-start;
  }

  @media ${device.mobileL} {
    padding: 72px 35px;
  }
`;

const FooterRightsWrap = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    margin-bottom: 18px;
  }

  @media ${device.mobileL} {
    align-items: flex-start;
  }
`;

const FooterRights = styled.p`
  font-size: 14px;
  margin-left: 27px;
  margin-right: 41px;
  opacity: 0.5;

  @media ${device.tabletS} {
    margin-right: 0;
    margin-left: 18px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
  }
`;

const FooterNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  flex-grow: 1;

  @media ${device.tabletS} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const FooterNavList = styled.div`
  display: flex;
  align-items: center;

  a {
    opacity: 0.5;
    margin-right: 18px;
    font-size: 14px;
    &:hover {
      opacity: 1;
    }

    &:last-child {
      margin-right: ${props => props.isLast ? '0' : '18px'};
    }

    @media ${device.tabletS} {
      opacity: 1;
      margin-right: 0;
      padding: 6px 0;
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }

    @media ${device.mobileL} {
      font-size: 12px;
    }
  }

  @media ${device.tabletS} {
    flex-direction: column;
    align-items: flex-start;
    &:first-child {
      margin-right: 50px;
    }
  }

  @media ${device.mobileL} {
    &:first-child {
      margin-right: 25px;
    }
  }
`;

const Footer = () => {
  const { keycloak } = useKeycloak();

  return (
    <FooterEl>
      <FooterWrap>
        <FooterRightsWrap>
          <Link to="/">
            <AllHealthLogo />
            <AllHealthLogoSm />
          </Link>
          <FooterRights>© 2023 all.health</FooterRights>
        </FooterRightsWrap>
        <FooterNav>
          {keycloak?.authenticated ? (
            <>
              <FooterNavList>
                <Link to="/">
                  home
                </Link>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://jobs.lever.co/all.health"
                >
                  careers
                </a>
                <Link to="/faq">
                  FAQ
                </Link>
              </FooterNavList>
              <FooterNavList isLast>
                <Link to="/privacy">
                  privacy policy
                </Link>
                <Link to="/contact-us">
                  contact us
                </Link>
                <Link
                  to="/"
                  onClick={event => {
                    event.preventDefault();
                    clearSession();
                    keycloak.logout({
                      redirectUri: `${window.location.origin}/`,
                    });
                  }}
                >
                  sign out
                </Link>
              </FooterNavList>
            </>
          ) : (
            <>
              <FooterNavList>
                <Link to="/">
                  home
                </Link>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://jobs.lever.co/all.health"
                >
                  careers
                </a>
                <Link to="/privacy">
                  privacy policy
                </Link>
              </FooterNavList>
              <FooterNavList isLast>
                <Link to="/contact-us">
                  contact us
                </Link>
                <Link to="/sign">
                  sign in
                </Link>
              </FooterNavList>
            </>
          )}
        </FooterNav>
      </FooterWrap>
    </FooterEl>
  );
};

export default Footer;
